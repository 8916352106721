@import "../../styles/variables.scss";
@import "../../styles/colors.scss";

#profile-info {
 .row {
   padding-bottom: 15px;
  }
  .ant-btn-primary {
    color: #fff;
    background-color: #00cc90;
    border-color: #00cc90;
   
  }
  
  .ant-btn-danger {
    color: #fff;
    background-color: #d43f3a;
    border-color: #d43f3a;
  }
}




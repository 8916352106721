.settings-upper {
  width: 100%;
  min-height: 387px;
  max-height: 387px;
  background: linear-gradient(180deg, #d6e5fc 0%, #f6fcff 100%);
}
.settings-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.security-tagline {
  max-width: 1225px;
  min-height: 47px;
  background: rgba(255, 199, 0, 0.07);
  border-radius: 12px;
}
.security-inner-tagline {
  margin-left: 23px;
  margin-top: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffc700;
  opacity: 0.8;
}
.settings-container {
  display: flex;
  flex-direction: column;
  padding: 46px;
  width: 96%;
  transform: translateY(-200px);
  max-width: 1337px;
  min-height: 496px;
  background: #ffffff;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.boxSections-sub-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.active-section {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 2px solid #2074f2;
  color: #2074f2;
  cursor: pointer;
}
.non-active-section {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  color: #000000;
}
.profile-heading {
  margin: 0;
  margin-top: 50px;
  margin-bottom: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  color: #222222;
}
.input-titles {
  margin: 0;
  margin-bottom: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #222222;
}
.input-fields {
  width: 430px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
}
.profile-change-email {
  width: 150px;
  height: 30px;
  margin-left: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #2074f2;
  border: 1px solid #2074f2;
  border-radius: 45px;
}
.profile-btn {
  width: 200px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  background: #2074f2;
  box-shadow: 0px 0px 14px rgba(32, 116, 242, 0.35);
  border-radius: 45px;
}
.profile-right-side {
  width: 100%;
  max-width: 637px;
  min-height: 345px;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 12px;
}
.profile-tagline {
  margin: 0;
  margin-left: 45px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #222222;

  opacity: 0.8;
}
.right-side-btn {
  width: 206px;
  height: 50px;
  margin-top: 45px;
  background: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  text-align: center;

  color: #2074f2;
  border: 1px solid #2074f2;
  border-radius: 45px;
}
.profile-verified {
  width: 90px;
  height: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;

  color: #16c784;
  border: 1px solid #16c784;
  border-radius: 45px;
}
.settings-heading {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #222222;
}
.non-active-section:nth-child(n + 2) {
  margin-left: 38px;
}
.settings-tags {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #222222;

  opacity: 0.8;
}
.settings-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #f2f2f2;
}
@media (max-width: 740px) {
  .settings-flex {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 1100px) {
  .buy-username {
    font-size: 12px;
  }
  .section-trade {
    margin-left: 18px;
  }

  .buy-trades {
    font-weight: 400;
    font-size: 10px;
  }
  .price-of-coin {
    margin-left: 20px;
    font-size: 12px;
  }
  .view-more-mobile {
    margin-bottom: 20px;
  }
  .buy-coin-sell-row {
    margin-bottom: 0;
    border: 0;
  }
  .buy-coin-btn {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid rgba(243, 243, 243, 0.7);
    padding: 20px;
  }
  .buy-coin-sell-header {
    display: none;
  }
  .coin-buy {
    max-width: 347px;
    height: 50px;
    width: 100%;
    border: 1px solid #2074f2;
    border-radius: 45px;
  }
  .buy-coin-sell-header-tag {
    margin: 0;
    margin-left: 18px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;

    color: #222222;

    opacity: 0.6;
  }
  .testimonial {
    width: 300px;
  }
  .active-section {
    font-size: 12px;
  }
  .non-active-section {
    font-size: 12px;
  }
  .sub-testimonial {
    width: 300px;
  }
  .buy-coin-sell {
    display: none;
  }
  .view-more {
    display: none;
  }
  .buy-coin-sell-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
    padding-left: 40px;
    padding-right: 10px;
    margin-top: 50px;
  }
}
@media (max-width: 1100px) {
  .active-section {
    font-size: 12px;
  }
  .non-active-section {
    color: #000000;
    font-size: 12px;
  }
}
@media (max-width: 600px) {
  .right-side-btn {
  }
  .profile-tagline {
    margin-left: 25px;
  }
}
@media (max-width: 400px) {
  .right-side-btn {
    width: 150px;
  }
  .non-active-section:nth-child(n + 2) {
    margin-left: 15px;
  }
}
@media (max-width: 350px) {
  .non-active-section:nth-child(n + 2) {
    margin-left: 10px;
  }
  .settings-container {
    padding: 23px;
  }
}


 
  
 .pagination {
    margin-top: 45px;
    margin-left: -40px;
    display: flex;
    list-style: none;
    outline: none;
   }
    
   .pagination>.active>a {
    background-color: #4677f4;
    color: #fff;
   }
    
   .pagination>li>a {
    border: 1px solid #4677f4;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
   }
    
   .pagination>li>a, .pagination>li>span {
    color: #4677f4;
    background-color: azure;
   }
   @media only screen  and (min-width : 1224px) and (max-width : 1824px) {
    .pagination{
      margin-left: 400px !important;
    }
    }
  
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
      .pagination{
        margin-left: 50px !important;
      }
      }
      .table-responsivea {
        // display: inline;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
.login-container {
  width: 100%;
  max-width: 1337px;
  min-height: 746px;
  background: #ffffff;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transform: translateY(-200px);
}
.right-panel-heading {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #222222;
}
.right-panel-sub-heading {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #222222;

  opacity: 0.8;
}
.right-panel-btn {
  width: 216px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  background: none;
  color: #2074f2;
  border: 1px solid #2074f2;
  border-radius: 45px;
}
.login-form-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #ffffff;

  width: 200px;
  height: 50px;
  background: #2074f2;
  box-shadow: 0px 0px 14px rgba(32, 116, 242, 0.35);
  border-radius: 45px;
}
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-upper {
  width: 100%;
  min-height: 387px;
  max-height: 387px;
  background: linear-gradient(180deg, #d6e5fc 0%, #f6fcff 100%);
}
.input-headers {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: #222222;
}
.input-field {
  width: 430px;
  height: 50px;
  padding-left: 20px;
  font-family: "Poppins";

  font-weight: 400;
  font-size: 14px;

  color: #222222;

  border: 1px solid #e9e9e9;
  border-radius: 45px;
}
.forget-password {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  text-align: right;

  color: #2074f2;
}
.remember {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
  color: #222222;
}
.remember-wrap {
  width: 175px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.forget-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.left-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 80px;
}
.login-header {
  margin: 0;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 30px;

  color: #222222;
}
.recaptcha {
  width: 100%;
  max-width: 330px;
  height: 80px;
  background: #f7f7f7;
  border: 1px solid #e9e9e9;
  border-radius: 13px;
}
.login-form-right {
  display: flex;
  flex-direction: column;
  width: 574px;
  min-height: 566px;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 8px;
  margin-right: 30px;
}
.right-panel {
  max-width: 474px;
  margin-top: 40px;
  margin-left: 50px;
}
@media (max-width: 1350px) {
  .login-container {
    width: 96%;
  }

  .left-panel {
    margin-left: 40px;
  }
}
@media (max-width: 550px) {
  .login-form-btn {
    width: 100%;
    max-width: 318px;
  }
  .left-panel {
    margin-left: 0;
  }
  .login-header {
    font-size: 24px;
    margin-bottom: 0 !important;
    padding-left: 1rem;
  }
  .input-field {
    width: 355px !important;
    margin-top: 2rem !important;
  }
  .input-headers, .login-form-btn{
    margin-top: 4rem !important;
  }
  .login-form-btn{max-width: 355px !important;}
  .login-form-right{
    margin-top: 6rem !important;
  }
  .username-ipt{
    margin-top: 2rem !important;
  }
  .forget-wrap{margin-bottom: 2rem !important;}
  .remember, .forget-password{
    margin-top: 2rem !important;
  }
}

@media (max-width: 1150px) {
  .login-container {
    flex-direction: column;
    justify-content: flex-start;
    max-width: 594px;
    height: auto;
  }
  .login-form-right {
    margin-left: 20px;
    align-items: center;
    width: 96%;
    height: auto;
    margin-bottom: 50px;
  }
  .right-panel {
    margin-left: 20px;
  }
  .left-panel {
    margin-top: 30px;
  }
  .remember {
    font-size: 12px;
  }
  .remember-wrap {
    width: 175px;
  }
  .forget-password {
    font-size: 12px;
  }
}

.offer-table-header {
  width: 96%;
  max-width: 1227px;
  height: 60px;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 8px;
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between;
}
.table-header-tag {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  /* black */

  color: #222222;

  opacity: 0.6;
}

@media (max-width: 1268px) {
  .offer-table-header {
    display: none;
  }
}
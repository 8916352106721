.order-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.order-upper {
  width: 100%;
  min-height: 387px;
  max-height: 387px;
  background: linear-gradient(180deg, #d6e5fc 0%, #f6fcff 100%);
}
.order-container {
  display: flex;
  flex-direction: column;
  padding: 46px;
  width: 96%;
  transform: translateY(-200px);
  max-width: 1337px;
  min-height: 496px;
  background: #ffffff;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.order-details-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
@media (max-width: 600px) {
  .order-container {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .active-section > a {
    font-size: 10px;
  }
  .non-active-section > a {
    font-size: 10px;
  }
  .wallet-tags {
    margin-right: 12px;
  }
}

@media (max-width: 900px) {
  .order-details-wrapper {
    align-items: center;
    flex-direction: column;
    width: 98%;
  }
}

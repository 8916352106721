.my-message {
  width: 100%;
  max-width: 336px;
  min-height: 95px;
  margin-bottom: 20px;
  background: rgba(22, 199, 132, 0.1);
  border-radius: 45px;
  padding-left: 32px;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-right: 32px;
  float: right;
}
.message-date {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  /* black */

  color: #222222;

  opacity: 0.8;
}
.message-text {
  margin: 0;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  /* black */

  color: #222222;

  opacity: 0.8;
}
.message-time {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  /* black */

  color: #222222;

  opacity: 0.8;
}
.seller-message {
  background: rgba(32, 116, 242, 0.1);
  border-radius: 45px;

  width: 100%;
  max-width: 336px;
  min-height: 95px;
  margin-bottom: 20px;
  padding-left: 32px;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-right: 32px;
  float: left;
}

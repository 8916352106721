.chat-wrapper {
  display: flex;
  flex-direction: column;
}
.chat-heading {
  margin: 0;
  font-family: "Poppins";
  font-weight: 800;
  font-size: 18px;
  line-height: 27px;
  color: #222222;
}
.chat-line-tags {
  width: 100%;
  max-width: 474px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */

  /* black */

  color: #222222;

  opacity: 0.8;
}
.remember-chat-tag {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  /* identical to box height, or 181% */

  /* black */

  color: #222222;

  opacity: 0.8;
}
.chat-group-tags {
  max-width: 432px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */

  /* black */

  color: #222222;

  opacity: 0.8;
}
.chat-message-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.chat-input {
  width: 250px;
  height: 50px;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  /* black */

  color: #222222;
  padding-left: 22px;
}
.chat-send-btn {
  width: 89px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #ffffff;

  background: #2074f2;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
}
.chat-box {
  width: 100%;
  max-width: 445px;
  max-height: 603px;
  height: 603px !important;
  overflow-y: scroll;
  padding: 34px 22px 34px 22px;
  margin-bottom: 30px;
  border: 2px solid #e9e9e9;
  border-radius: 25px;
}
.refresh-btn {
  width: 50px;
  height: 50px;
  margin-left: 6px;
  background: none;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
}

.close-contract-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 30px;
}
.close-contract-header {
  width: 100%;
  padding-left: 19px;
  padding-right: 19px;
  max-width: 1227px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 8px;
}
.close-contract-header-tag {
  margin: 0;
  font-family: "Poppins";
  margin-right: 5px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  /* black */

  color: #222222;

  opacity: 0.6;
}
.pagination-container > li.active > a{
  outline: none;
  background: none;
}
.close-contract-row-data-tag {
  margin: 0;
  margin-right: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  /* identical to box height */

  /* black */

  color: #222222;
}
.close-contract-row {
  width: 100%;

  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d8d8d8;

  max-width: 1227px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.close-contract-row-btn {
  width: 90px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  background: none;
  color: #2074f2;

  border: 1px solid #2074f2;
  border-radius: 45px;
}
.mobile-sub-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row-sub-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.close-contract-row-mobile {
  display: none;
}
.close-contract-row-mobile-btn {
  display: none;
}
.pagination-container {
  list-style: none;
  height: 50px;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
  max-width: 422px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 60px;
}
.pagination-container > li {
  padding-right: 19px;
}

.pagination-container > ul {
  padding-left: 0;
}
.pagination-container > li.active > a {
  color: #ffffff !important;
}
.pagination-container > li > a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  /* identical to box height */

  /* black */

  color: #222222;

  opacity: 0.6;
}
.pagination-container > li.active {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding-top: 15px;
  padding-left: 15px;
  min-width: 36px;
  height: 48px;
  background: #2074f2;
  color: #ffffff !important;
}
.pagination-container > li:nth-child(n + 2) {
  padding-left: 19px;
  padding-right: 19px;
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}
@media (max-width: 925px) {
  .close-contract-header {
    display: none;
  }
  .close-contract-row {
    display: none;
  }
  .close-contract-row-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .close-contract-row-mobile-btn {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .close-contract-header-tag {
    font-size: 12px;
    margin-bottom: 5px;
    text-align: center;
  }
  .close-contract-row-data-tag {
    font-size: 10px;
    text-align: center;
  }
}

@import "./styles/variables.scss";
@import "./styles/colors.scss";
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .ant-layout {
   width: 100% !important;
  }
  }
.App,
.ant-layout {
  min-height: inherit;
}
header.ant-layout-header {
  height: 74px;
}

main.ant-layout-content {
  background-color: $primaryLightColor;
}
.sendWOW{
  color: #00cc90;
  width: 200px;
  margin-bottom: 17px;
}
.sendWOWa{
  width: 240px;
  color: #00cc90;
  margin-bottom: 17px;
}
.sendWOWb{
  width: 70%;
  color: #00cc90;
  margin-bottom: 17px;
}
.labl {
  display: block;
  width: 400px;
}
.labl > input {
  /* HIDE RADIO */
  width: 100%;
  visibility: hidden; /* Makes input not-clickable */
  position: absolute; /* Remove input from document flow */
}
.labl > input + div {
  width: 118px;
  height: 50px;
  margin-left: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  /* identical to box height */

  text-align: center;

  color: #000000;
  border: 1px solid #000000;
  border-radius: 45px;
}
.labl > input:checked + div {
  width: 118px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border: none;
  /* identical to box height */

  color: #ffffff;
  background: #16c784;
  border-radius: 45px;
}
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.radioselected {
  width: 118px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border: none;
  /* identical to box height */

  color: #ffffff;
  background: #16c784;
  border-radius: 45px;
}
.label.ant-radio-button-wrapper > .ant-radio-button-wrapper-checked {
  width: 118px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border: none;
  /* identical to box height */

  color: #ffffff;
  background: #16c784;
  border-radius: 45px;
}
.label.ant-radio-button-wrapper {
  width: 118px;
  height: 50px;
  margin-left: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 45px;
}
.radiononselected {
  width: 118px;
  height: 50px;
  margin-left: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #000000;
  border: 1px solid #000000;
  border-radius: 45px;
}
.dynamic-wrap {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 430px;
  min-height: 147px;
  border: 2px solid #16c784;
  border-radius: 25px;
}
.dynamic-sub-tag {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  /* black */

  color: #222222;
}
.dynamic-tag {
  margin: 0;

  margin-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #16c784;
}
.change-btn {
  width: 100%;
  max-width: 200px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  background: none;
  text-align: center;

  color: #2074f2;

  border: 1px solid #2074f2;
  border-radius: 45px;
}
.next-btn {
  width: 100%;
  max-width: 200px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  margin-top: 41px;
  color: #ffffff;

  background: #2074f2;
  box-shadow: 0px 0px 14px rgba(32, 116, 242, 0.35);
  border-radius: 45px;
}
.selectbuyorsell {
  width: 100%;
  max-width: 430px;
  height: 50px;
  margin-top: 20px;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
  margin-right: 20px;
}
.white-btn {
  width: 215px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background: none;
  /* identical to box height */

  text-align: center;
  color: #e8e8e8;
  border: 1px solid #e8e8e8;
  border-radius: 45px;
}
.selectbuyorsell-margin-input {
  width: 100%;
  max-width: 430px;
  height: 50px;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
  margin-right: 20px;
}
.margin-selectbuyorsell {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  width: 100%;
  max-width: 430px;
  height: 50px;
  margin-top: 20px;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
  margin-right: 20px;
}
.confirm-detail-message {
  width: 100%;
  max-width: 1225px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 47px;
  background: rgba(255, 199, 0, 0.07);
  border-radius: 12px;
  padding-top: 9px;
  padding-left: 23px;
}
.confirm-message {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  /* identical to box height, or 181% */
  color: #ffc700;
  opacity: 0.8;
}
.select-hours {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height */
  /* black */
  color: #222222;

  opacity: 0.7;
}
.selectbuyorsell-textarea {
  width: 100%;
  padding-top: 10px;
  max-width: 430px;
  min-height: 200px;
  margin-top: 20px;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
  margin-right: 20px;
}
.buyorsellcoin {
  margin: 0;
  margin-bottom: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  /* black */
  color: #222222;
}
.boxSections-sub-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.buy-coin-sell-header-tag {
  margin: 0;
  margin-left: 35px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #222222;
  opacity: 0.6;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 1px;
  height: 0% !important;
  /* padding: 1px 0; */
  background-color: #ddd;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  content: '';
}
@media (max-width: 1100px) {
  .buy-username {
    font-size: 12px;
  }
  .section-trade {
    margin-left: 18px;
  }

  .buy-trades {
    font-weight: 400;
    font-size: 10px;
  }
  .price-of-coin {
    margin-left: 20px;
    font-size: 12px;
  }
  .view-more-mobile {
    margin-bottom: 20px;
  }
  .buy-coin-sell-row {
    margin-bottom: 0;
    border: 0;
  }
  .buy-coin-btn {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid rgba(243, 243, 243, 0.7);
    padding: 20px;
  }
  .buy-coin-sell-header {
    display: none;
  }
  .coin-buy {
    max-width: 347px;
    height: 50px;
    width: 100%;
    border: 1px solid #2074f2;
    border-radius: 45px;
  }
  .buy-coin-sell-header-tag {
    margin: 0;
    margin-left: 18px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;

    color: #222222;

    opacity: 0.6;
  }
  .testimonial {
    width: 300px;
  }
  .active-section {
    font-size: 12px;
  }
  .non-active-section {
    font-size: 12px;
  }
  .sub-testimonial {
    width: 300px;
  }
  .buy-coin-sell {
    display: none;
  }
  .view-more {
    display: none;
  }
  .buy-coin-sell-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
    padding-left: 40px;
    padding-right: 10px;
    margin-top: 50px;
  }
}
.buy-coin-sell-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1250px;
  height: 60px;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 8px;
}

.new-offer-explore-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 96%;
  margin-top: 80px;
  transform: translateY(-250px);
  max-width: 1337px;

  min-height: 583px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.active-section {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 2px solid #2074f2;
  color: #2074f2;
  cursor: pointer;
}
.non-active-section {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  color: #000000;
}
.input-wrapper {
  display: flex;
  flex-direction: column;
}
.input-margin-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.input-margin-tag {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height */

  /* black */

  color: #222222;

  opacity: 0.7;
}
.sub-sellorbuytag {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  /* black */

  color: #222222;

  opacity: 0.7;
}
.radioTitles {
  margin: 0;
  margin-left: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  /* black */

  color: #222222;
}
.radioWrapInput {
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 170px;
  margin-right: 10px;
}
.upper-part {
  width: 100%;
  min-height: 387px;
  max-height: 387px;
  background: linear-gradient(180deg, #d6e5fc 0%, #f6fcff 100%);
}
.boxSections-sub-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1100px) {
  .active-section {
    font-size: 12px;
  }
  .non-active-section {
    font-size: 12px;
  }
}
@media (max-width: 719px) {
  .change-btn {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media (max-width: 776px) {
  .input-margin-wrap {
    flex-direction: column;
    align-items: flex-start;
  }
  .input-margin-tag {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.transaction-table-head {
  width: 100%;
  max-width: 1227px;
  margin-top: 50px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 19px;
  padding-right: 19px;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 8px;
}
.heading-tags {
  margin: 0;
  margin-right: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;

  /* black */

  color: #222222;

  opacity: 0.6;
}
.pagination-container {
  list-style: none;
  height: 50px;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
  max-width: 422px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 60px;
}
.pagination-container > li {
  padding-right: 19px;
}

.pagination-container > ul {
  padding-left: 0;
}
.pagination-container > li.active > a {
  color: #ffffff !important;
}
.pagination-container > li > a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  /* identical to box height */

  /* black */

  color: #222222;

  opacity: 0.6;
}
.pagination-container > li.active {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding-top: 15px;
  padding-left: 15px;
  min-width: 36px;
  height: 48px;
  background: #2074f2;
  color: #ffffff !important;
}
.pagination-container > li:nth-child(n + 2) {
  padding-left: 19px;
  padding-right: 19px;
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}
.transaction-data-row {
  width: 100%;
  max-width: 1227px;
  margin-top: 50px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d8d8d8;
}
.data-tag {
  margin: 0;
  margin-right: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* black */

  color: #222222;
}
.transaction-data-row-mobile {
  display: none;
}
@media (max-width: 980px) {
  .transaction-table-head {
    display: none;
  }
  .transaction-data-row {
    display: none;
  }
  .transaction-data-row-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: 19px;
    margin-right: 19px;
  }
  .table-data-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .table-title-tag {
    margin: 0;
    margin-bottom: 10px;
    min-width: 62px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 21px;
    /* identical to box height */

    /* black */

    color: #222222;

    opacity: 0.6;
  }
  .data-tag {
    margin-right: 0;
    min-width: 52px;
    margin-bottom: 22px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
    /* identical to box height */

    /* black */

    color: #222222;
  }
  .table-data-col-wrapper {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 450px) {
  .table-title-tag {
    max-width: 50px;
  }
  .data-tag {
    max-width: 45px;
  }
  .table-data-col-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .transaction-data-row-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 19px;
    margin-right: 19px;
  }
}
@media (max-width: 350px) {
  .transaction-data-row-mobile {
    margin-left: 5px;
    margin-right: 5px;
  }
}

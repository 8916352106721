@import "../../styles/variables.scss";
@import "../../styles/colors.scss";

.search-container {
  max-width: $breakpoint-max;
  margin: 0 auto;
  width: 95%;
  display: flex;
  flex-direction: column;
  h1 {
    font-weight: 700;
  }
  .search-options-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0%;

    .search-option {
      display: flex;
      flex-direction: column;
      font-size: 1rem;
      .ant-select {
        cursor: pointer;
      }
      .ant-select-selector {
        &:hover,
        &:focus {
          border-color: $primaryGreen;
          outline: 0 solid $primaryGreen;
        }
      }
      .ant-input {
        line-height: 1.5;
        font-size: 1.25rem;
      }

      label {
        padding-left: 8%;
      }
    }
  }
  .advanced {
    div:nth-of-type(1) {
      flex: 1;
    }
    div:nth-of-type(2) {
      flex: 2;
    }
  }
  a {
    font-size: 1rem;
    text-align: right;
    color: $primaryGreen;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
      color: $secondaryGreen;
    }
  }
  .advanced-search {
    display: flex;
    justify-content: flex-end;
  }
}
@media only screen  and (min-width : 1224px) and (max-width : 1800px) {
  .coin-top{
    margin-top: -50px !important;
  }
  // .select-buy-sell{
  //   margin-left: 100px !important;
  // }
  .head_all{
    height: 40px;
  }
  }

  @media only screen and (max-width : 320px) and (max-width : 480px) {
    .head_all{
      height: 60px;
    }
    }
    


    
.buy-trades {
  margin: 0;
  margin-top: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #222222;

  opacity: 0.6;
}
.coin-buy {
  width: 90px;
  height: 50px;
  background: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #2074f2;
  border: 1px solid #2074f2;
  border-radius: 45px;
}
.price-of-wow-coin {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #16c784;
}
.price-of-coin {
  margin: 0;
  margin-left: 35px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #222222;
}
.buy-username {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #2074f2;
}
.section-trade {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  align-items: center;
}
.buy-coin-sell-row {
  width: 100%;
  margin-top: 20px;
  border-bottom: 1px solid rgba(243, 243, 243, 0.7);
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1227px;
}
.buy-coin-sell-row-mobile {
  display: none;
}
.buy-coin-sell-row-mobile-2 {
  display: none;
}
.buy-coin-mobile-screen {
  display: none;
}
.buy-coins-titles {
  margin: 0;
  margin-bottom: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  color: #222222;

  opacity: 0.6;
}
.buy-coin-wrappers {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1100px) {
  .buy-username {
    font-size: 12px;
  }
  .buy-coin-mobile-screen {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .buy-coin-line {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .section-trade {
    margin-left: 18px;
  }

  .buy-trades {
    font-weight: 400;
    font-size: 10px;
  }
  .price-of-coin {
    margin-left: 20px;
    font-size: 12px;
  }
  .view-more-mobile {
    margin-bottom: 20px;
  }
  .buy-coin-sell-row {
    display: none;
  }
  .buy-coin-sell-row-mobile {
    width: 100%;
    margin-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1227px;
    margin-bottom: 0;
    border: 0;
  }
  .buy-coin-sell-row-mobile-2 {
    width: 95%;
    margin-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1227px;
    margin-bottom: 0;
    border: 0;
  }
  .buy-coin-btn {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid rgba(243, 243, 243, 0.7);
    padding: 20px;
  }
  .coin-buy {
    max-width: 347px;
    height: 50px;
    width: 100%;
    border: 1px solid #2074f2;
    border-radius: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .buy-coin-sell-header-tag {
    margin: 0;
    margin-left: 18px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;

    color: #222222;

    opacity: 0.6;
  }
  .testimonial {
    width: 300px;
  }
  .active-section {
    font-size: 12px;
  }
  .non-active-section {
    font-size: 12px;
  }
  .sub-testimonial {
    width: 300px;
  }
  .buy-coin-sell {
    display: none;
  }
  .view-more {
    display: none;
  }
  .buy-coin-sell-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
    padding-left: 40px;
    padding-right: 10px;
    margin-top: 50px;
  }
}

.notiall-upper {
  width: 100%;
  min-height: 387px;
  max-height: 387px;
  background: linear-gradient(180deg, #d6e5fc 0%, #f6fcff 100%);
}
.notiall-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.verified-badge {
  width: 60px;
  height: 21px;
  margin-top: 26px;
  margin-bottom: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  color: #16c784;
  text-align: center;

  opacity: 0.8;
  background: rgba(22, 199, 132, 0.09);
  border-radius: 20px;
}
.verify-right-bottom {
  width: 100%;
  margin-top: 30px;
  max-width: 637px;
  min-height: 82px;
  border: 1px solid #2074f2;
  border-radius: 12px;
}
.account-guide {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #2074f2;
}
.account-guide-small {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;

  color: #2074f2;
}
.verify-top-right {
  width: 98%;
  max-width: 637px;
  min-height: 307px;
  padding-top: 47px;
  padding-left: 47px;
  display: flex;
  flex-direction: column;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 12px;
}
.verify-top-right-btn {
  width: 340px;
  height: 50px;
  margin-top: 38px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;

  background: #2074f2;
  box-shadow: 0px 0px 14px rgba(32, 116, 242, 0.35);
  border-radius: 45px;
}

.verify-userKyc {
  margin: 0;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  /* identical to box height */

  /* black */

  color: #222222;
}
.tiers-tags {
  margin: 0;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #222222;

  opacity: 0.8;
}

.verification-sub-container {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
}
.tiers-tags:nth-child(n + 2) {
  margin-left: 12px;
}
.tiers-tags-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tier-heading {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  /* black */

  color: #222222;
}
.non-verified-badge {
  margin-top: 26px;
  margin-bottom: 20px;
  width: 89px;
  height: 21px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  color: #000000;
  text-align: center;
  opacity: 0.8;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.left-verification-box {
  max-width: 405px;
  width: 98%;
  padding-left: 23px;
  min-height: 234px;
  border: 2px solid #e9e9e9;
  border-radius: 25px;
}
.left-verification-box:first-child {
  margin-bottom: 30px;
}
.security-tagline {
  max-width: 1225px;
  min-height: 47px;
  background: rgba(255, 199, 0, 0.07);
  border-radius: 12px;
}
.security-inner-tagline {
  margin-left: 23px;
  margin-top: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffc700;
  opacity: 0.8;
}
.notiall-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 46px;
  width: 96%;
  transform: translateY(-200px);
  max-width: 1337px;
  min-height: 496px;
  background: #ffffff;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.notification-table-header {
  width: 100%;
  max-width: 1225px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 8px;
}

.notification-message-cover {
  margin-left: 33px;
  width: 100%;
}
.notification-message {
  margin: 0;

  width: 100%;
  min-width: 75px;
  max-width: 533px;
  left: 473px;
  top: 404px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* black */

  color: #222222;
}
.notification-table-header-message {
  margin: 0;
  margin-left: 33px;
  width: 100%;
  min-width: 75px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #222222;

  opacity: 0.6;
}
.notification-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.pagination-container {
  list-style: none;
  height: 50px;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
  max-width: 422px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 60px;
}
.pagination-container > li {
  padding-right: 19px;
}

.pagination-container > ul {
  padding-left: 0;
}
.pagination-container > li.active > a {
  color: #ffffff !important;
}
.pagination-container > li > a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  /* identical to box height */

  /* black */

  color: #222222;

  opacity: 0.6;
}
.pagination-container > li.active {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding-top: 15px;
  padding-left: 15px;
  min-width: 36px;
  height: 48px;
  background: #2074f2;
  color: #ffffff !important;
}
.pagination-container > li:nth-child(n + 2) {
  padding-left: 19px;
  padding-right: 19px;
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}
.pagination-sub-container {
  list-style: none;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  /* identical to box height */

  /* black */

  color: #222222;

  opacity: 0.6;
}
.notification-view {
  margin: 0;
  margin-right: 33px;
  min-width: 44px;
  max-width: 59px;
  text-align: end;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #2074f2;
}
.notification-status-viewed {
  margin: 0;
  margin-right: 33px;
  min-width: 44px;
  max-width: 59px;
  text-align: end;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #16c784;
}
.notification-table-header-status {
  margin: 0;
  margin-right: 33px;
  width: 100%;
  align-items: flex-end;
  min-width: 44px;
  max-width: 59px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #222222;

  opacity: 0.6;
}
.notification-table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 1225px;
  padding-bottom: 32px;
  padding-top: 32px;
  height: auto;
}
.breaker {
  width: 100%;
  max-width: 1225px;
  height: 0px;

  opacity: 0.1;
  border: 1px solid #222222;
}
.notification-Id {
  margin: 0;
  margin-left: 33px;
  width: 100%;
  min-width: 50px;
  max-width: 75px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* black */

  color: #222222;
}
.notification-table-header-Id {
  margin: 0;
  margin-left: 33px;
  width: 100%;
  min-width: 50px;
  max-width: 75px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #222222;

  opacity: 0.6;
}
.table-header {
  width: 100%;
  max-width: 1225px;
  height: 60px;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 8px;
}
.notification-table {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.table-body {
  margin-top: 32px;
}
.table-header-row {
  border: none;

  border-radius: 8px;
}
.table-header-tags {
  border: none;
}
.notification-table {
  display: flex;
  flex-direction: column;

  width: 100%;
}
.notification-table-body {
  display: flex;

  flex-direction: column;

  width: 100%;
}
.boxSections-sub-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.active-section {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 2px solid #2074f2;
  color: #2074f2;
  cursor: pointer;
}
.non-active-section {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  color: #000000;
}
.profile-heading {
  margin: 0;
  margin-top: 50px;
  margin-bottom: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  color: #222222;
}
.input-titles {
  margin: 0;
  margin-bottom: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #222222;
}
.input-fields {
  width: 430px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
}
.profile-change-email {
  width: 150px;
  height: 30px;
  margin-left: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #2074f2;
  border: 1px solid #2074f2;
  border-radius: 45px;
}
.profile-btn {
  width: 200px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  background: #2074f2;
  box-shadow: 0px 0px 14px rgba(32, 116, 242, 0.35);
  border-radius: 45px;
}
.profile-right-side {
  width: 100%;
  max-width: 637px;
  min-height: 345px;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 12px;
}
.profile-tagline {
  margin: 0;
  margin-left: 45px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #222222;

  opacity: 0.8;
}
.right-side-btn {
  width: 206px;
  height: 50px;
  margin-top: 45px;
  background: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  text-align: center;

  color: #2074f2;
  border: 1px solid #2074f2;
  border-radius: 45px;
}
.profile-verified {
  width: 90px;
  height: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;

  color: #16c784;
  border: 1px solid #16c784;
  border-radius: 45px;
}
.settings-heading {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #222222;
}
.settings-tags {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #222222;

  opacity: 0.8;
}
.settings-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #f2f2f2;
}
@media (max-width: 740px) {
  .settings-flex {
    flex-direction: column;
    align-items: flex-start;
  }
  .notiall-container {
    width: 100%;
    padding-left: 13px;
    padding-right: 13px;
  }
}
@media (max-width: 992px) {
  .verification-sub-container {
    flex-direction: column-reverse;
  }
  .verify-top-right {
    margin-top: 30px;
  }
}
@media (max-width: 1100px) {
  .buy-username {
    font-size: 12px;
  }
  .section-trade {
    margin-left: 18px;
  }

  .buy-trades {
    font-weight: 400;
    font-size: 10px;
  }
  .price-of-coin {
    margin-left: 20px;
    font-size: 12px;
  }
  .view-more-mobile {
    margin-bottom: 20px;
  }
  .buy-coin-sell-row {
    margin-bottom: 0;
    border: 0;
  }
  .buy-coin-btn {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid rgba(243, 243, 243, 0.7);
    padding: 20px;
  }
  .buy-coin-sell-header {
    display: none;
  }
  .coin-buy {
    max-width: 347px;
    height: 50px;
    width: 100%;
    border: 1px solid #2074f2;
    border-radius: 45px;
  }
  .buy-coin-sell-header-tag {
    margin: 0;
    margin-left: 18px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;

    color: #222222;

    opacity: 0.6;
  }
  .testimonial {
    width: 300px;
  }
  .active-section {
    font-size: 12px;
  }
  .non-active-section {
    font-size: 12px;
  }
  .sub-testimonial {
    width: 300px;
  }
  .buy-coin-sell {
    display: none;
  }
  .view-more {
    display: none;
  }
  .buy-coin-sell-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
    padding-left: 40px;
    padding-right: 10px;
    margin-top: 50px;
  }
}
@media (max-width: 1100px) {
  .active-section {
    font-size: 12px;
  }
  .non-active-section {
    color: #000000;
    font-size: 12px;
  }
}
@media (max-width: 600px) {
  .right-side-btn {
  }
  .profile-tagline {
    margin-left: 25px;
  }
}
@media (max-width: 400px) {
  .right-side-btn {
    width: 150px;
  }
}
@media (max-width: 464px) {
  .notification-message-cover {
    margin-left: 13px;
  }
  .verify-top-right-btn {
    max-width: 300px;
  }
  .verify-top-right {
    padding-left: 10px;
  }
}

@media (max-width: 376px) {
  .verify-top-right-btn {
    max-width: 200px;
  }
}
.buy-coin-sell {
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 96%;
  padding-left: 55px;
  padding-right: 10px;
  margin-top: 50px;
}
.buy-coin-sell-header-mobile {
  width: 96%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 950px;
  margin-left: 35px;
  margin-right: 35px;
  margin-top: 24px;
  height: 40px;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 8px;
}
.view-more-mobile-div {
  max-width: 347px;
  width: 100%;
  margin-bottom: 20px;

  height: 50px;
}
.view-more-mobile {
  max-width: 347px;
  width: 100%;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  background: #2074f2;
  box-shadow: 0px 0px 14px rgba(32, 116, 242, 0.35);
  border-radius: 45px;
}
.view-more-mobile-section-1 {
  display: none;
}
.buy-coin-sell-header-tag {
  margin: 0;
  margin-left: 35px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  color: #222222;

  opacity: 0.6;
}
.coin-buy {
  width: 90px;
  height: 50px;
  background: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #2074f2;
  border: 1px solid #2074f2;
  border-radius: 45px;
}
.buy-coin-sell-rows {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.buy-coin-sell-main-col {
  width: 100%;
  border-bottom: 1px solid rgba(243, 243, 243, 0.7);
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;

  max-width: 1227px;
}
.buy-coin-sell-row {
  width: 100%;
  margin-top: 20px;
  border-bottom: 1px solid rgba(243, 243, 243, 0.7);
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1227px;
}
.buy-coin-sell-mobile {
  display: none;
}
.buy-coin-sell-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1250px;
  height: 60px;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 8px;
}
.section-trade {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  align-items: center;
}
.price-of-coin {
  margin: 0;
  margin-left: 35px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #222222;
}
.buy-trades {
  margin: 0;
  margin-top: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #222222;

  opacity: 0.6;
}
.buy-username {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #2074f2;
}
.price-of-wow-coin {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #16c784;
}
.box {
  display: flex;
  flex-direction: column;
  width: 96%;
  margin-bottom: 50px;
  transform: translateY(-200px);
  max-width: 1337px;
  min-height: 496px;
  background: #ffffff;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.box-2 {
  display: flex;
  flex-direction: column;
  width: 96%;
  transform: translateY(-200px);
  min-height: 496px;
  background: #ffffff;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.active-section {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 2px solid #2074f2;
  color: #2074f2;
  cursor: pointer;
}
.non-active-section {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  color: #000000;
}

.boxSections-sub-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.boxSections {
  margin-left: 55px;
  margin-right: 55px;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.extraMargin{
  margin-left: 55px;
  margin-right: 55px;
  margin-top: 50px;
}
.view-more {
  width: 157px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  background: #2074f2;
  box-shadow: 0px 0px 14px rgba(32, 116, 242, 0.35);
  border-radius: 45px;
}
.tab-section-box {
  width: 95%;
  max-width: 1337px;
  height: 496px;
  transform: translateY(-200px);
  background: #ffffff;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.section4 {
  min-height: 702px;
  width: 100%;
  background: rgba(243, 243, 243, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section1-sub-lp {
  max-width: 1032px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  transform: translateY(-200px);
}
.section1-sub-lp-img {
  max-width: 136px;
}
.section1-sub-wrapper {
  max-width: 160px;
  margin-top: 20px;
  max-height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 76px;
}
.section1-sub-wrap-text {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: 12px;
  text-align: center;

  /* black */

  color: #222222;
}
.section1-sub-lp {
  max-width: 1032px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.section4-name {
  margin: 0;
  font-family: "Poppins";
  margin-top: 14px;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 16px;

  text-align: center;

  color: #222222;
}
.section4-title {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  text-align: center;
  margin-bottom: 11px;
  /* black */

  color: #222222;

  opacity: 0.5;
}
.section4-description {
  margin: 0;
  overflow-wrap: break-word;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  width: 300px;
  height: auto;
  text-align: center;
  color: #222222;
  opacity: 0.8;
}
.slider {
  width: 90%;
  max-width: 1440px;
}
.testimonial {
  display: flex;
  width: 403px;
  flex-direction: column;
  align-items: center;
  margin-right: 53px;
}
.testimonial-pic {
  max-width: 80px;
  max-height: 80px;
  height: 80px;
  transform: translateY(50%);
  border-radius: 999999px;
  object-fit: cover;
  object-position: top;
}
.sub-testimonial {
  width: 403px;
  height: 274px;
  background: #ffffff;
  box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.star-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.section4-sub-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section4-heading {
  margin: 0;
  text-align: center;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 36px;
  color: #222222;
}
.section1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 644px;
}
.section1-upper-part {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/backgrounds/hero-image.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 644px;
}
.section1-lower-part {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  width: 100%;
  min-height: 344px;
}
.section1-heading {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  text-align: center;
  color: #222222;
}
.section1-heading-span {
  color: #2074f2;
}
.section1-sub-heading {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  max-width: 896px;
  /* black */

  color: #222222;

  opacity: 0.8;
}
.section6-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/backgrounds/home-section6.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 344px;
  margin-top: 30px;
}
.section-3-register {
  width: 136px;
  height: 50px;
  border: 1px solid #2074f2;
  border-radius: 45px;
  background: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #2074f2;
  margin-right: 10px;
}
.section-3-show {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #2074f2;
  background: none;
  border: none;
}
.feature-img {
  width: 60px;
  height: 60px;
}
.feature-heading {
  margin: 0;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  color: #222222;
}
.feature-icon {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: rgba(32, 116, 242, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.feature-wrap {
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
}
.feature-img {
  width: 80%;
  height: 80%;
}
.sub-feature-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 476px;
  margin-left: 28px;
}
.feature-sub-heading {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: #222222;
  opacity: 0.8;
}
.section3-heading {
  margin: 0;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 36px;
  color: #222222;
}
.section3-sub-heading {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: #222222;
  opacity: 0.8;
  margin-bottom: 53px;
}
.section3-left-side {
  max-width: 702px;
  margin-right: 90px;
}
.section-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/backgrounds/home-section2.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 735px;
  padding-top: 30px;
}
.section-2-btn {
  width: 193px;
  height: 50px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  text-align: center;
  margin-bottom: 20px;
  color: #ffffff;
  background: #16c784;
  border-radius: 45px;
}
.section-2-btn:hover,
:focus {
  background: #ffffff;
  color: #16c784;
}
.section-2-heading {
  margin: 0;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
  color: #fffdfd;
}
.section-2-sub-heading {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  max-width: 896px;
  margin-bottom: 93px;
  color: #ffffff;
  opacity: 0.8;
}
.section6-login-btn {
  width: 140px;
  height: 50px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: #2074f2;
  border: 1px solid #2074f2;
  background: none;
  border-radius: 45px;
}
.content-box-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 73px;
}
.content-box-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 223px;
  height: 234px;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.content-box-container:nth-child(n + 2) {
  margin-left: 21px;
}
.content-box-img {
  width: 223px;
  height: 105px;
}
.content-box-heading {
  margin: 0;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: 19px;
  margin-bottom: 9px;
  text-align: center;

  color: #222222;
}
.content-box-line {
  margin: 0;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  text-align: center;

  color: #222222;

  opacity: 0.8;
}
.section6-register-btn {
  width: 140px;
  height: 50px;
  background: #2074f2;
  box-shadow: 0px 0px 14px rgba(32, 116, 242, 0.35);
  border-radius: 45px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  margin-left: 10px;
}
.section6-heading {
  width: 620px;
  overflow-wrap: break-word;
  height: auto;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  margin-bottom: 40px;
  color: #222222;
}
.section6-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section5 {
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.section3 {
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-bottom: 40px;
}
.section5-right {
  width: 95%;
  object-fit: contain;
  max-width: 600px;
  max-height: 600px;
  margin-right: 50px;
}
.section3-right {
  width: 95%;
  object-fit: contain;
  max-width: 600px;
  max-height: 600px;
  margin-right: 50px;
}
.section5panel {
  background-color: white;
  border: none;
  border-bottom: 1px solid #222222;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  color: #222222;
}
.section5-heading {
  margin: 0;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 36px;
  color: #222222;
}
.section5-sub-heading {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: #222222;
  opacity: 0.8;
}
.section5-right-side {
  max-width: 702px;
}
@media (max-width: 640px) {
  .section6-heading {
    width: 98%;
    font-size: 28px;
  }
}
@media (max-width: 1200px) {
  .section5-right {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .section3-right {
    margin-right: 0;
    margin-top: 30px;
  }
  .section5 {
    flex-direction: column;
    justify-content: center;
  }
  .section5-heading {
    font-size: 20px;
    margin-left: 10px;
    width: 98%;
  }
  .section5-sub-heading {
    font-size: 12px;
    margin-left: 10px;
    width: 98%;
  }
  .section5-right-side {
    width: "98%";
  }
}

@media (max-width: 1444px) {
  .content-box-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 184px;
    height: 194px;
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }
  .content-box-img {
    width: 184px;
    height: 85px;
  }
}
@media (max-width: 1210px) {
  .content-box-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 184px;
    height: 194px;
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }
  .content-box-img {
    width: 184px;
    height: 85px;
  }
  .content-box-container:nth-child(n + 2) {
    margin-left: 0;
  }
  .content-box-container {
    margin-top: 10px;
  }
  .content-box-container:nth-child(3) {
    margin-right: 10px;
    margin-left: 10px;
  }
  .content-box-container:nth-child(4) {
    margin-right: 10px;
  }
  .content-box-container:first-child {
    margin-right: 10px;
  }
  .content-box-container:last-child {
    margin-left: 10px;
  }
}
@media (max-width: 775px) {
  .content-box-container:nth-child(1) {
    margin-left: 10px;
  }
  .section1-heading {
    font-size: 24px;
  }
}
@media (max-width: 582px) {
  .content-box-container:nth-child(1) {
    margin-left: 0px;
  }
}
@media (max-width: 1100px) {
  .box {
    transform: translateY(-200px);
  }
  .view-more-mobile-section-1 {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .view-more-section-one {
    width: 100%;
    max-width: 347px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    background: #2074f2;
    box-shadow: 0px 0px 14px rgba(32, 116, 242, 0.35);
    border-radius: 45px;
  }
  .section1-sub-wrapper {
    padding: 20px;
    margin-right: 0;
  }
  .buy-username {
    font-size: 12px;
  }
  .section-trade {
    margin-left: 18px;
  }

  .buy-trades {
    font-weight: 400;
    font-size: 10px;
  }
  .price-of-coin {
    margin-left: 20px;
    font-size: 12px;
  }
  .view-more-mobile {
    margin-bottom: 20px;
  }
  .buy-coin-sell-row {
    margin-bottom: 0;
    border: 0;
  }
  .buy-coin-btn {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid rgba(243, 243, 243, 0.7);
    padding: 20px;
  }
  .coin-buy {
    max-width: 347px;
    height: 50px;
    width: 100%;
    border: 1px solid #2074f2;
    border-radius: 45px;
  }
  .buy-coin-sell-header-tag {
    margin: 0;
    margin-left: 18px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;

    color: #222222;

    opacity: 0.6;
  }
  .testimonial {
    width: 300px;
  }
  .active-section {
    font-size: 12px;
  }
  .non-active-section {
    font-size: 12px;
  }
  .sub-testimonial {
    width: 300px;
  }
  .buy-coin-sell {
    display: none;
  }
  .view-more {
    display: none;
  }
  .buy-coin-sell-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
    padding-left: 40px;
    padding-right: 10px;
    margin-top: 50px;
  }
}
@media (max-width: 1350px) {
  .section3 {
    flex-direction: column;
  }
  .feature-wrap {
    align-items: center;
    width: 98%;
  }
  .section3-left-side {
  }
  .section3-sub-heading {
  }
  .sub-feature-wrap {
    width: 316px;
  }
  .feature-icon {
    width: 75px;
    height: 75px;
  }
}
@media (max-width: 750px) {
  .section3-left-side {
    margin-left: 50px;
  }
  .feature-icon {
    width: 55px;
    height: 55px;
  }
}

@media (max-width: 500px) {
  .feature-sub-heading {
    text-align: center;
    font-size: 12px;
    overflow-wrap: break-word;
  }

  .feature-heading {
    font-size: 18px;
    overflow-wrap: break-word;
  }
  .section3-heading {
    text-align: center;

    font-size: 24px;
    width: 98%;
    overflow-wrap: break-word;
  }
  .section3-sub-heading {
    text-align: center;

    font-size: 12px;
    height: auto;
    overflow-wrap: break-word;
  }
  .section3-left-side {
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .sub-feature-wrap {
    margin-left: 14px;
  }
  .feature-icon {
    width: 45px;
    height: 45px;
  }
  .feature-img {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 390px) {
  .feature-icon {
    display: none;
  }
  .section3-sub-heading {
    width: 90%;
  }
  .sub-feature-wrap {
    margin-left: 0;
    align-items: center;
  }
  .feature-sub-heading {
    width: 90%;
  }
}
@media (max-width: 1440px) {
  .testimonial {
    margin-right: 0;
  }
  .slider {
    max-width: 1040px;
  }
}
@media (max-width: 780px) {
  .slider {
    max-width: 300px;
  }

  .testimonial {
    margin-right: 0;
    max-width: 300px;
  }
  .testimonial-pic {
    width: 90px;
    height: 90px;
  }
}

p {
  margin: 0;
}
.main {
  background-color: white;
  padding-top: 76px;
  display: flex;
  flex-direction: column;
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
}
.tagline {
  margin: 0;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  margin-bottom: 16px;
  overflow-wrap: break-word;
  opacity: 0.8;
}
.logo-wrap {
  display: flex;
  flex-direction: column;
  margin-right: 69px;
}
.wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.wrap2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0px;
}
.logo-detail {
  font-family: "Poppins";
  margin: 0;
  margin-left: 14px;
  max-width: 165px;
  overflow-wrap: break-word;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  opacity: 0.8;
}
.heading {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  color: #222222;
}
.link {
  margin: 0;
  font-family: "Poppins";
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  color: #222222;
  opacity: 0.8;
}
.nav-links-wrap {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.sub_logo_wrap {
  display: flex;
  flex-direction: column;
}
.icon-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.icon-wrap2 > .icon {
  width: 90px;
  height: 28px;
  cursor: pointer;
}
.icon-store-wrap {
  display: flex;
  flex-direction: column;
}
.icon-wrap2 > .icon:nth-child(2) {
  margin-left: 10px;
}

@media (max-width: 1200px) {
  .logo-wrap {
    margin-bottom: 20px;
    margin-right: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 295px;
  }
  .wrap2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 295px;
    padding-left: 80px;
  }

  .logo-detail {
    min-width: 275px;
    font-size: small;
  }

  .nav-wrap {
    margin-top: 10px;
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .heading {
    font-size: 14px;
  }
  .link {
    font-size: 12px;
  }
  .nav-wrap {
    margin-left: 40px;
  }
  .nav-links-wrap {
    justify-content: space-between;
  }
}
@media (max-width: 428px) {
  .nav-wrap {
    /* margin-left: 50px; */
  }
  .icon-wrap2 {
    display: flex;
    flex-direction: column;
  }
  .icon-wrap2 > .icon:nth-child(2) {
    margin-left: 0px;
    margin-top: 10px;
  }
}

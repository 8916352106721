.notibutton {
  background: #00cc90;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
}
.offerdet {
  display: none !important;
}

.notiall-upper {
  width: 100%;
  min-height: 387px;
  max-height: 387px;
  background: linear-gradient(180deg, #d6e5fc 0%, #f6fcff 100%);
}
.notiall-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.notiall-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 46px;
  width: 96%;
  transform: translateY(-200px);
  max-width: 1337px;
  min-height: 496px;
  background: #ffffff;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.profile-heading {
  margin: 0;
  margin-top: 50px;
  margin-bottom: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  text-align: left;
  font-size: 36px;
  color: #222222;
}
.card {
  width: 100%;
  border-radius: 8px;
}

.right-panel {
  max-width: 474px;
  margin-left: 50px;
}
.textarea {
  max-width: 430px;
  width: 100%;
  min-height: 115px;
  height: auto;
  padding: 15px 20px 15px 20px;

  border: 1px solid #e9e9e9;
  border-radius: 25px;
}
.signup-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.right-panel-btn {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 216px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  background: none;
  color: #2074f2;
  border: 1px solid #2074f2;
  border-radius: 45px;
}
.right-panel-sub-heading {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #222222;

  opacity: 0.8;
}
.right-panel-heading {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #222222;
}
.signup-form-right {
  display: flex;
  flex-direction: column;
  width: 574px;
  height: 370px;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 8px;
  margin-right: 30px;
}
.input-field {
  width: 430px;
  height: 50px;
  padding-left: 20px;
  font-family: "Poppins";
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 14px;

  color: #222222;

  border: 1px solid #e9e9e9;
  border-radius: 45px;
}
.signup-form-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #ffffff;

  width: 200px;
  height: 50px;
  background: #2074f2;
  box-shadow: 0px 0px 14px rgba(32, 116, 242, 0.35);
  border-radius: 45px;
}
.input-headers {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: #222222;
  margin-bottom: 12px;
}
.signup-header {
  margin: 0;
  margin-top: 30px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 30px;

  color: #222222;
}
.signup-upper {
  width: 100%;
  min-height: 387px;
  max-height: 387px;
  background: linear-gradient(180deg, #d6e5fc 0%, #f6fcff 100%);
}
.left-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 80px;
}
.signup-container {
  width: 100%;
  max-width: 1337px;
  min-height: 746px;
  background: #ffffff;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transform: translateY(-200px);
}
@media (max-width: 1350px) {
  .signup-container {
    width: 96%;
  }

  .left-panel {
    margin-left: 40px;
  }
}
@media (max-width: 1150px) {
  .sign-form {
    width: 100%;
  }
  .signup-container {
    flex-direction: column;
    justify-content: flex-start;
    max-width: 594px;
    height: auto;
  }
  .signup-form-right {
    margin-left: 20px;
    align-items: center;
    width: 96%;
    height: auto;
    margin-bottom: 50px;
  }
  .right-panel {
    margin-left: 20px;
  }
  .left-panel {
    margin-top: 30px;
  }
  .remember {
    font-size: 12px;
  }
  .remember-wrap {
    width: 175px;
  }
  .forget-password {
    font-size: 12px;
  }
}
@media (max-width: 550px) {
  .login-form-btn {
    width: 100%;
    max-width: 318px;
  }
  .left-panel {
    margin-left: 0;
    width: 96%;
  }
  .login-header {
    font-size: 24px;
  }
  .input-field {
    width: 98%;
  }
}
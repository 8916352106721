.mytrade-page {
  display: flex;
  flex-direction: column;
}
.options-wrap {
  display: flex;
  margin-top: 40px;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.options-wrap > p:nth-child(2) {
  margin-left: 20px;
}
.contract-options {
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  padding-top: 8px;
  color: #2074f2;
  width: 338px;
  height: 40px;
  background: #f7f7f7;
  border: 1px solid #2074f2;
  border-radius: 45px;
}
.contract-non-options {
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  padding-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: rgba(0, 0, 0, 0.4);
  width: 338px;
  height: 40px;

  background: #f7f7f7;
  border-radius: 45px;
}
.mytrade-upper {
  width: 100%;
  min-height: 387px;
  max-height: 387px;
  background: linear-gradient(180deg, #d6e5fc 0%, #f6fcff 100%);
}
.mytrade-container {
  display: flex;
  flex-direction: column;
  padding: 46px;
  width: 96%;
  transform: translateY(-200px);
  max-width: 1337px;
  min-height: 496px;
  background: #ffffff;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.profile-heading {
  margin: 0;
  margin-top: 50px;
  margin-bottom: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  color: #222222;
}
@media (max-width: 600px) {
  .mytrade-container {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .active-section > a {
    font-size: 10px;
  }
  .non-active-section > a {
    font-size: 10px;
  }
  .wallet-tags {
    margin-right: 12px;
  }
}
@media (max-width: 675px) {
  .options-wrap {
    flex-direction: column;
  }
  .options-wrap > p:nth-child(2) {
    margin-left: 0px;
  }
}
@media (max-width: 367px) {
  .contract-options {
    width: 95%;
    font-size: 10px;
  }
  .contract-non-options {
    width: 95%;
    font-size: 10px;
  }
}
@media (max-width: 1160px) {
}

.orderinfo-container {
  width: 100%;
  max-width: 574px;
  min-height: 984px;
  padding: 40px 50px 40px 50px;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 8px;
  margin-left: 10px;
}
.cancel_btn {
  margin-top: 25px;
  margin-bottom: 25px;
  width: 206px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ff0000;
  border-radius: 45px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}
.paid_btn {
  margin-top: 25px;
  margin-bottom: 25px;
  width: 206px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;

  background: #16c784;
  border-radius: 45px;
}
.detail-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  /* black */

  color: #222222;
  margin: 0;
  margin-bottom: 14px;
}
.offer-tagline {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */

  /* black */

  color: #222222;

  opacity: 0.8;
}
.sub-heading-tag {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  margin-bottom: 20px;
  /* or 181% */

  /* black */

  color: #222222;
}


@media only screen and (max-width: 600px) {
  .orderinfo-container{
    margin-top: 3rem;
  }
  .chat__submit{
    margin-top: 3rem !important;
  }
  .chat__submit .chat-send-btn{
    width: 10rem !important;
  }
  .chat-input{
    width: 300px !important;
  }
  .my-message{
    min-height: 0 !important;
  }
}

@media only screen and (max-width: 370px) {
  .chat-input{
    width: 250px !important;
  }
}
// @import "../../styles/variables.scss";
// @import "../../styles/colors.scss";
// .padabc{
//   padding-top: 2px !important;
//   padding-bottom: 2px !important;
//   }
// .order-info-main {
//   display: flex;
//   flex-direction: column;
//   padding: 0 5%;
//   flex: 1;

//   p {
//     margin-bottom: 1%;
//   }

//   .action-ctn {
//     display: flex;
//     justify-content: space-between;
//     padding-bottom: 5%;
//     border-bottom: 1px solid $secondaryLightColor;

//     //temporary
//     margin-top: 20%;

//     .amount-info {
//       flex: 3;
//       p:nth-child(odd) {
//         color: $secondaryDarkColor;
//       }
//       p:nth-child(even) {
//         font-weight: bold;
//         font-size: 1.2rem;
//         color: $primaryDarkColor;
//       }
//     }

//     .action-buttons {
//       display: flex;
//       flex-direction: column;
//       flex: 2;
//       justify-content: flex-end;
//       span {
//         font-size: 0.8rem;
//       }
//       button:nth-child(2) {
//         margin: 2% 0;
//       }
//     }
//   }

//   .details-ctn {
//     padding-top: 5%;
//     display: flex;

//     h3 {
//       font-weight: bold;
//     }
//     .counterparty-info {
//       flex: 1;
//     }

//     .terms-info {
//       flex: 1;
//       span {
//         font-weight: bold;
//       }
//     }
//   }
// }
// .ant-btn {
//     line-height: 1.5715;
//     position: relative;
//     display: inline-block;
//     font-weight: 400;
//     white-space: nowrap;
//     text-align: center;
//     background-image: none;
//     border: 1px solid transparent;
//     -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
//     box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
//     cursor: pointer;
//     -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
//     transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     -ms-touch-action: manipulation;
//     touch-action: manipulation;
//     height: 32px;
//     padding: 2.4px 15px;
//     font-size: 16px;
//     border-radius: 4px;
//     color: #4c4c4c;
//     background-color: #fff;
//     border-color: #ddd;
// }
@media only screen and (min-device-width : 1025px) and (max-device-width : 5000px) {
    .deskmob{
        margin-top: -100px;
    }
    }

.tag {
  border-radius: 99999px;
  background: rgba(22, 199, 132, 0.10);
  width: fit-content;
  padding: 8px 16px;
  align-self: flex-end;
}

.tag p {
  margin-bottom: 0;
  color: #16C784;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.notification-head {
  margin: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row-notifications {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row-notifications2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.link-head {
  margin: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.avatar-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000000;
}

.new-offer-btn {
  width: 140px;
  height: 50px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
  border-radius: 25px;
  color: #ffffff;

  background: #2074f2;
  box-shadow: 0px 0px 14px rgba(32, 116, 242, 0.35);
  border-radius: 45px;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  padding: 12px 16px;
  z-index: 1;
  width: 157px;
  height: auto;
  background: #ffffff;
  border-radius: 4px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.main-web-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: fixed;
  justify-content: center;
  z-index: 10;
}

.main-web-header-login {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: fixed;
  justify-content: center;
  z-index: 10;
}

.main-mob-header {
  display: none;
}

.main-mob-header-login {
  display: none;
}

.web-header {
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mob-header {
  height: 70px;
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mob-header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.web-header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home {
  margin: 0;
  font-family: "Poppins";
  margin-left: 70px;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

.tags {
  margin: 0;
  font-family: "Poppins";
  cursor: pointer;
  margin-left: 50px;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.tagsLogin {
  margin: 0;
  font-family: "Poppins";
  cursor: pointer;
  margin-left: 30px;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.flag-img {
  cursor: pointer;
}

.login-btn {
  margin-left: 50px;
  width: 140px;
  height: 50px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: #2074f2;
  border: 1px solid #2074f2;
  background: none;
  border-radius: 45px;
}

.register-btn {
  width: 140px;
  height: 50px;
  background: #2074f2;
  box-shadow: 0px 0px 14px rgba(32, 116, 242, 0.35);
  border-radius: 45px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  margin-left: 10px;
}

@media (max-width: 1045px) {
  .main-web-header {
    display: none;
  }

  .main-mob-header {
    z-index: 10;

    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: fixed;
    justify-content: center;
  }

  .home {
    margin-left: 0;
  }

  .tags {
    margin-left: 0;
  }
}

@media (max-width: 1291px) {
  .main-web-header-login {
    display: none;
  }

  .main-mob-header-login {
    z-index: 10;

    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: fixed;
    justify-content: center;
  }

  .dropdown-content {
    border: 1px solid black;
  }

  .home {
    margin-left: 0;
  }

  .tags {
    margin-left: 0;
  }

  .tagsLogin {
    margin-left: 0;
  }

  .link-head {
    margin-left: 0;
    max-width: 70px;
  }

  .row-notifications {
    max-width: 65px;
    margin-left: 0;
    margin-bottom: 25px;
  }

  .row-notifications2 {
    max-width: 65px;
    margin-left: 0;
  }

  .notification-head {}
}
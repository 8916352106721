.header {
    display: flex;
    background: linear-gradient(#6616fc, #f394d3);
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;
    height: 80px;
  }
  .logo-nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    width: 45px;
    height: 45px;
  }
  .nav-options {
    padding-left: 25px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 50px;
    list-style-type: none;
  }
  .mobile-option {
    display: none;
  }
  .mobile-menu {
    display: none;
  }
  
  .option :hover {
    color: white;
  }
  .signin-up {
    display: flex;
    padding: 0px 5px;
    list-style-type: none;
  }
  .sign-in {
    padding-right: 50px;
    align-self: center;
  }
  .sign-in :hover {
    color: white;
  }
  .signup-btn {
    padding: 10px 10px;
    height: 2.4rem;
    border-radius: 3px;
    background: rgb(222, 9, 241);
    color: white;
    cursor: pointer;
    align-items: center;
  }
  .navbar-collapse {
    flex-grow: 0 !important;
  }
  @media only screen  and (min-width : 1224px) and (max-width : 6000px) {
    
    .desk-dropa{
      display: none !important;
    }
    .mob-en-flag{
      display: none;
    }
    .mob-en-flag2{
      display: none;
    }
    .mob-en-flag3{
      display: none;
    }
    .mob-en-flag3a{
      display: none;
    }
    .drop-lap{
      width: 400px;
    }
    }

    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
      
      .mob-dropa{
        display: none !important;
      }
      .sign-btn{
        width: 110px;
      }
      .mob-en-flag{
        margin-top: -75px;
        margin-left: 180px;
      }
      .mob-en-flag2{
        margin-top: -75px;
        margin-left: 230px;
      }

      .mob-en-flag3{
        width: 20px;
        height: 20px;
        margin-left: 280px;
      }
      .mob-en-flag3a{
        margin-left: 285px;
        margin-top: -70px;
        background: red;
        color: #fff;
        padding: 5px;
        width: 30px;
        border-radius: 15px;
      }
     
      .mob-flag-none{
        display: none;
      }
      }

      .mobile {
        display: none;
      }
      
      @media only screen and (max-width: 768px) {
        .mobile {
          display: block;
        }
      }
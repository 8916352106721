@import "../../styles/variables.scss";
@import "../../styles/colors.scss";

.past-trades-ctn {
  margin-bottom: 2%;
  h3 {
    font-weight: bold;
  }
  p {
    color: $secondaryDarkColor;
  }
}

@import "../../../styles/variables.scss";
@import "../../../styles/colors.scss";

.trade-card-ctn {
  display: flex;
  overflow: auto;
  max-width: $breakpoint-max;
  flex: 1;
  margin: 1% 0;
  padding: 2% 0 0 2%;
  border-radius: 6px;
  border: 1px solid $secondaryLightColor;

  div {
    flex: 1;
    p:first-child {
      font-weight: bold;
    }
  }
  div:last-child {
    max-width: 100px;
    margin: auto;
  }
}
/* Desktops and laptops ----------- */
@media only screen  and (min-width : 1224px) and (max-width : 1824px)  {
  .desabc{
    display: none;
  }
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .desabc{
      display: none;
    }
    }
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
      .tabmob{
        padding: 5px !important;
      }
      }
      

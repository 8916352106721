.withdraw-container {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-left: 55px;
}
.transaction-row-mobile {
  display: none;
}
.heading-no {
  margin: 0;
  margin-top: 20px;
  margin-right: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  color: #222222;

  opacity: 0.6;
}
.heading-other-tags {
  margin: 0;
  margin-top: 9px;
  margin-right: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  color: #222222;

  opacity: 0.6;
}
.transaction-completed {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-right: 5px;
  text-align: center;
  /* identical to box height */

  color: #16c784;
  width: 108px;
  height: 23px;

  background: rgba(22, 199, 132, 0.1);
  border-radius: 45px;
}
.transaction-pending {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #ffc700;
  width: 83px;
  height: 23px;

  background: rgba(255, 199, 0, 0.1);
  border-radius: 45px;
}
.transaction-price {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-right: 5px;

  line-height: 24px;
  /* identical to box height */

  color: #16c784;
}
.transaction-row {
  display: flex;
  margin-top: 25px;
  padding-left: 19px;
  padding-right: 19px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1227px;
  height: 80px;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 8px;
  padding-bottom: 25px;
  border-bottom: 1px solid #d8d8d8;
}
.transaction-data {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  margin-right: 5px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* black */

  color: #222222;
}
.transaction-header {
  display: flex;
  padding-left: 19px;
  padding-right: 19px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1227px;
  height: 80px;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 8px;
}
.input-wrapper-withdraw-coin {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.input-label-withdraw-coin {
  margin: 0;
  margin-bottom: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* black */

  color: #222222;
}
.withdraw-coin-input {
  width: 100%;
  max-width: 430px;
  height: 50px;

  background: rgba(233, 233, 233, 0.4);
  border: 1px solid #e9e9e9;
  border-radius: 45px;
  cursor: not-allowed;
}
.transaction-row-mobile-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}
.heading-wrapper {
  display: flex;
  flex-direction: column;
}
.pagination-container {
  list-style: none;
  height: 50px;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
  max-width: 422px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 60px;
}
.pagination-container > li {
  padding-right: 19px;
}

.pagination-container > ul {
  padding-left: 0;
}
.pagination-container > li.active > a {
  color: #ffffff !important;
}
.pagination-container > li > a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  /* identical to box height */

  /* black */

  color: #222222;

  opacity: 0.6;
}
.pagination-container > li.active {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding-top: 15px;
  padding-left: 15px;
  min-width: 36px;
  height: 48px;
  background: #2074f2;
  color: #ffffff !important;
}
.pagination-container > li:nth-child(n + 2) {
  padding-left: 19px;
  padding-right: 19px;
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}
.withdraw-coin-input-amount {
  width: 100%;
  max-width: 430px;
  height: 50px;
  padding-left: 20px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
}
.submit-coin-btn {
  width: 200px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #ffffff;

  background: #2074f2;
  box-shadow: 0px 0px 14px rgba(32, 116, 242, 0.35);
  border-radius: 45px;
}
@media (max-width: 440px) {
  .withdraw-container {
    margin-left: 20px;
  }
  .transaction-price {
    font-size: 14px;
  }
  .heading-other-tags {
    font-size: 12px;
  }
  .heading-no {
    font-size: 12px;
  }
  .transaction-data {
    font-size: 14px;
  }
}
@media (max-width: 1010px) {
  .transaction-header {
    display: none;
  }
  .transaction-row {
    display: none;
  }
  .transaction-row-mobile {
    display: flex;
    flex-direction: column;
    width: 98%;
  }
  .heading-other-tags {
    margin-bottom: 12px;
  }
  .heading-no {
    margin-bottom: 12px;
  }
}

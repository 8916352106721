$primaryDarkColor: #111724;
$primaryLightColor: #fff;
$primaryGreen: #00cc90;

$secondaryGreen: #06a075;
$secondaryLightColor: #ddd;
$secondaryDarkColor: #4c4c4c;

$triDarkColor: #666;

$errorRed: #bb1817;

//custom theme antd...see config-overrides for detail
// $primary-color: #00cc90;
// $link-color: #00cc90;
// $success-color: #00cc90;
// $warning-color: #faad14;
// $error-color: #f5222d;
// $font-size-base: 16px;
// $heading-color: #111724;
// $text-color: #4e4e4e;
// $text-color-secondary: #4c4c4c;
// $disabled-color: #4c4c4c;
// $border-radius-base: 4px;
// $border-color-base: #ddd;
// $box-shadow-base: 0 0 10px rgba(0, 0, 0, 0.1);

.input_text{
    color: grey;
    background: #efefef;
    padding: 10px;
    border: none;
    margin-right: 20px;
}
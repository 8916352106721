.send-wow-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.sub-send-wow-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1065px;
}
.sub-send-wow-container:nth-child(1) {
  margin-bottom: 30px;
}
.wallet-stats {
  margin-top: 45px;
  width: 100%;
  max-width: 1205px;
  min-height: 210px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270.35deg, #2074f2 1.96%, #16c784 106.36%);
  border-radius: 21px;
}
.wallet-send-input-wrapper {
  display: flex;
  flex-direction: column;
}
.more-options {
  margin: 0;
  margin-bottom: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  color: #222222;
}
.wallet-send-input-header {
  margin: 0;
  margin-bottom: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* black */

  color: #222222;
}
.wallet-send-input-field {
  width: 100%;
  padding-left: 20px;
  max-width: 430px;
  height: 50px;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
}
.send-btn {
  margin-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #ffffff;

  width: 200px;
  height: 50px;
  background: #2074f2;
  box-shadow: 0px 0px 14px rgba(32, 116, 242, 0.35);
  border-radius: 45px;
}
.stats-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 482px;
  height: 50px;
  background: rgba(233, 233, 233, 0.4);
  border: 1px solid #e9e9e9;
  border-radius: 45px;
  padding: 30px 13px 30px 13px;
  margin-left: 15px;
  margin-right: 15px;
}
.stats-heading {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.stats-heading-value {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #ffffff;
}
@media (max-width: 935px) {
  .sub-send-wow-container {
    flex-direction: column;
  }
  .stats-container:nth-child(1) {
    margin-top: 30px;
  }
  .stats-container {
    margin-bottom: 30px;
  }
  .sub-send-wow-container:nth-child(1) {
    margin-bottom: 0px;
  }
  .wallet-ipt{
    height: 80px;
  }
  .more-options{
    margin-top: -16px !important;
  }
}
@media (max-width: 435px) {
  .sub-send-wow-container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .stats-heading {
    font-size: 10px;
  }
  .stats-heading-value {
    font-size: 10px;
  }
}

.receive-address {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
}
.copy-address {
  text-align: center;
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* black */

  color: #222222;

  width: 100%;
  max-width: 499px;
  height: 50px;
  background: rgba(233, 233, 233, 0.4);
  border: 1px solid #e9e9e9;
  border-radius: 45px;
}
.copy-btn {
  width: 206px;
  height: 50px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  background: white;
  color: #2074f2;
  border: 1px solid #2074f2;
  border-radius: 45px;
  margin-left: 20px;
}
.old-address-card {
  width: 100%;
  margin-top: 44px;
  padding-left: 38px;
  max-width: 1223px;
  min-height: 193px;
  border: 2px solid #e9e9e9;
  border-radius: 25px;
}
.old-address-header {
  margin: 0;
  margin-top: 21px;
  margin-bottom: 13px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  /* identical to box height */

  /* black */

  color: #222222;
}
.qr-card {
  width: 100%;
  padding-left: 38px;
  margin-top: 40px;
  max-width: 1223px;
  min-height: 365px;

  border: 2px solid #e9e9e9;
  border-radius: 25px;
}
.old-header-line {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 1134px;
  width: 100%;
  /* black */

  color: #222222;
}
.old-header-note {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 1134px;
  width: 100%;
  /* black */

  color: #ff0000;
}

@media (max-width: 700px) {
  .receive-address {
    flex-direction: column;
    align-items: flex-start;
  }
  .copy-btn {
    margin-top: 20px;
    margin-left: 0px;
  }
}
@media (max-width: 460px) {
  .copy-address {
    font-size: 9px;
  }
  .copy-btn {
    margin-top: 20px;
    margin-left: 0px;
  }
}

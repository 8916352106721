.input-header {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-left: 2px !important;
  color: #222222;
}
.page-link-first {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  border: 1px solid #e9e9e9;
  min-width: 69px;
  padding: 20px;

  border-bottom-left-radius: 45px;
  border-top-left-radius: 45px;
  /* black */

  color: #222222;

  opacity: 0.6;
}
.page-link-last {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  border: 1px solid #e9e9e9;
  min-width: 69px;
  padding: 20px;

  border-bottom-right-radius: 45px;
  border-top-right-radius: 45px;
  /* black */

  color: #222222;

  opacity: 0.6;
}
.page-link-tags {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  border: 1px solid #e9e9e9;
  min-width: 69px;
  padding: 20px;

  /* black */

  color: #222222;

  opacity: 0.6;
}
.non-active-pages {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  background: #ffffff;
  min-width: 36px;
  padding: 20px;
  border: 1px solid #e9e9e9;

  /* black */

  color: #222222;

  opacity: 0.6;
}
.pages {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  background: #2074f2;
  min-width: 36px;
  padding: 20px;

  /* black */

  color: #ffffff;

  opacity: 0.6;
}
.input-wraps {
  margin-right: 20px;
}
.input-fields {
  width: 230px;
  max-width: 228px;
  height: 50px;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding-left: 20px;
  color: #222222;
}
.filters-web {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.buy-wow-coin-sell-web-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1250px;
  height: 60px;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 8px;
}
.submitBtn {
  width: 200px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  background: #2074f2;
  box-shadow: 0px 0px 14px rgba(32, 116, 242, 0.35);
  border-radius: 45px;
}
.filters-mob {
  display: none;
}
.pagination-buy-coin {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin-top: 10px;
}

@media (max-width: 375px) {
  .input-fields {
    width: 100%;

    height: 50px;
  }
  .submitBtn {
    width: 280px;
  }
}
@media (max-width: 460px) {
  .input-fields {
    width: 100%;

    height: 50px;
  }
  .submitBtn {
    width: 280px;
  }
}
@media (max-width: 570px) {
  .input-fields {
    width: 100%;
    height: 50px;
  }
}
@media (max-width: 580px) {
  .filters-web {
    display: none;
  }
  .filters-mob {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }
  .input-fields-mob {
    width: 200px;
    max-width: 210px;
    height: 50px;
    border: 1px solid #e9e9e9;
    border-radius: 45px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-left: 20px;
    color: #222222;
  }
  .ant-form .ant-form-item .ant-form-item-control {
    /* display: none; */
    max-height: 0px;
  }

  .submitBtn {
    width: 100%;
  }

  .filter-mob-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
@media (max-width: 478px) {
  .input-fields-mob {
    width: 160px;
    max-width: 170px;
  }
}

@media (max-width: 410px) {
  .input-fields-mob {
    width: 130px;
    max-width: 140px;
  }
}

@media (max-width: 1100px) {
  .buy-wow-coin-sell-web-header {
    display: none;
  }
}
@media (max-width: 431px) {
  .page-link-first {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 21px;
    /* identical to box height */
    border: 1px solid #e9e9e9;
    width: auto;
    padding: 5px;

    border-bottom-left-radius: 45px;
    border-top-left-radius: 45px;
    /* black */

    color: #222222;

    opacity: 0.6;
  }
  .page-link-last {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 21px;
    /* identical to box height */
    border: 1px solid #e9e9e9;
    width: auto;
    padding: 5px;

    border-bottom-right-radius: 45px;
    border-top-right-radius: 45px;
    /* black */

    color: #222222;

    opacity: 0.6;
  }
  .page-link-tags {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 21px;
    /* identical to box height */
    border: 1px solid #e9e9e9;
    width: auto;
    padding: 5px;

    /* black */

    color: #222222;

    opacity: 0.6;
  }
  .non-active-pages {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 21px;
    /* identical to box height */
    background: #ffffff;
    width: auto;
    padding: 5px;
    border: 1px solid #e9e9e9;

    /* black */

    color: #222222;

    opacity: 0.6;
  }
  .pages {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 21px;
    /* identical to box height */
    background: #2074f2;
    width: auto;
    padding: 5px;

    /* black */

    color: #ffffff;

    opacity: 0.6;
  }
}
@media (max-width: 350px) {
  .submitBtn {
    width: 220px;
  }
}

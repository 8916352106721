.accordion-title{
    background: #fff !important;
    border-radius: 3px 3px 0 0;
    white-space: normal;
    // display: flex;
    // align-items: center;
    justify-content: space-between;
    padding: 1rem 1.875rem;
    margin-bottom:5px;
}
.accordion-content{
    padding: 10px;
}
.viewHome{
    padding: 2px 7px;
    background: #c9c5c5;
    border-radius: 5px;
}
.offerdetails-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.offerdetails-upper {
  width: 100%;
  min-height: 387px;
  max-height: 387px;
  background: linear-gradient(180deg, #d6e5fc 0%, #f6fcff 100%);
}
.offerdetails-container {
  display: flex;
  flex-direction: column;
  padding: 63px 56px 63px 56px;
  width: 96%;
  transform: translateY(-200px);
  max-width: 1337px;
  min-height: 496px;
  background: #ffffff;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.profile-heading {
  margin: 0;
  margin-top: 50px;
  margin-bottom: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  color: #222222;
}
.sub-offerdetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.go-back-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.go-back {
  margin: 0;
  margin-left: 9px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  /* identical to box height, or 217% */

  letter-spacing: 0.384px;

  color: #23272a;

  mix-blend-mode: normal;
}
.offers-input-fields {
  width: 100%;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  /* black */

  color: #222222;
}
.offer-textarea {
  width: 100%;
  padding: 15px 22px 0 22px;
  max-width: 430px;
  height: 115px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  /* black */

  color: #222222;

  border: 1px solid #e9e9e9;
  border-radius: 25px;
}
.offers-btn {
  width: 200px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #ffffff;
  text-align: center;
  background: #2074f2;
  box-shadow: 0px 0px 14px rgba(32, 116, 242, 0.35);
  border-radius: 45px;
}
.offer-wrapper-tags {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-right: 17px;
  /* identical to box height */

  /* black */

  color: #222222;
}
.offers-input-fields-wrappers {
  width: 100%;
  max-width: 430px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
  padding-left: 22px;
}
.trade-amount-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
}
.offer-tags {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* black */

  color: #222222;
}
.sub-offerdetails-left {
  display: flex;
  max-width: 430px;
  width: 100%;
  flex-direction: column;
}
.wow-line-tag {
  margin: 0;
  width: 100%;
  max-width: 474px;
  height: 55px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */

  /* black */

  color: #222222;

  opacity: 0.8;
  margin-bottom: 30px;
}
.details-container {
  width: 100%;
  max-width: 474px;
  display: flex;
  flex-direction: column;
}
.offerInfo {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  /* black */

  color: #222222;
  margin-bottom: 14px;
}
.details-tag {
  margin: 0;
  margin-bottom: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */

  /* black */

  color: #222222;
}
.details-tag-span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */

  /* black */

  color: #222222;
}
.sub-offerdetails-right {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.wow-dollars {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  /* black */

  color: #222222;
  margin-bottom: 14px;
}
.sub-offerdetails-right-container {
  width: 100%;
  max-width: 574px;
  min-height: 407px;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-top: 40px;
  padding-bottom: 40px;
  background: rgba(243, 243, 243, 0.7);
  border-radius: 8px;
}
@media (max-width: 600px) {
  .offerdetails-container {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .active-section > a {
    font-size: 10px;
  }
  .non-active-section > a {
    font-size: 10px;
  }
  .wallet-tags {
    margin-right: 12px;
  }
}
@media (max-width: 907px) {
  .sub-offerdetails {
    flex-direction: column;
    align-items: center;
  }
  .sub-offerdetails-right {
    margin-top: 20px;
  }
}
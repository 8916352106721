@import "../../styles/variables.scss";
@import "../../styles/colors.scss";

.onfido-sdk-ui-crossDevice-Intro-list {
  list-style: none;
  padding: 0;
}

.onfido-sdk-ui-crossDevice-Intro-stageIcon {
  background-size: contain;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  float: left;
  height: 3em;
  width: 3em;
  position: relative;
  z-index: 1;
}

.onfido-sdk-ui-crossDevice-Intro-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.onfido-sdk-ui-Theme-step {
  width: 100%;
  min-height: 100%;
  height: 0;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
}


.onfido-sdk-ui-DocumentSelector-option {
  width: 100%;
  color: var(--osdk-color-content-doc-type-button);
  background: var(--osdk-color-background-doc-type-button);
  border-radius: var(--osdk-border-radius-button);
  border-color: var(--osdk-color-border-doc-type-button);
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  font: inherit;
  line-height: 1.43;
  margin-bottom: .5em;
  padding: 1em;
  position: relative;
}

.onfido-sdk-ui-DocumentSelector-option::after {
  background: url('data:image/svg+xml,%3Csvg width%3D%228%22 height%3D%2212%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E %3Cpath d%3D%22M.332 1.811 5.262 6l-4.93 4.189a1.014 1.014 0 0 0 0 1.5 1.192 1.192 0 0 0 1.604 0l5.736-4.873A1.01 1.01 0 0 0 7.997 6a1.01 1.01 0 0 0-.325-.816L1.936.31a1.192 1.192 0 0 0-1.604 0 1.014 1.014 0 0 0 0 1.5z%22 fill%3D%22%232C3E4F%22%2F%3E%3C%2Fsvg%3E') no-repeat;
  background-size: contain;
  content: "";
  display: block;
  height: .75em;
  position: absolute;
  right: 1em;
  top: 50%;
  -webkit-transform: translateY(-0.375em);
  transform: translateY(-0.375em);
  width: .5em;
}

.onfido-sdk-ui-DocumentSelector-list {
  list-style: none;
  padding: 0;
  margin-top: .5em;
}

.onfido-sdk-ui-Modal-inner, .onfido-sdk-ui-Modal-inner *, .onfido-sdk-ui-Modal-inner *::before, .onfido-sdk-ui-Modal-inner *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onfido-sdk-ui-DocumentSelector-option.onfido-sdk-ui-DocumentSelector-optionHoverDesktop:hover {
  -webkit-box-shadow: 0 0 0 2px var(--osdk-color-border-doc-type-button-hover);
  box-shadow: 0 0 0 2px var(--osdk-color-border-doc-type-button-hover);
}
.onfido-sdk-ui-Modal-inner {
  font-size: 16px;
  border-radius: 2%;
  padding: 5%;
  border: 2px solid #333;
  position: relative;
  overflow: auto;
  margin: auto;
  width: 94vw;
  max-width: 32em;
  height: 37.5em;
  text-align: center;
  max-height: calc(100% + 0.25em);
  background-color: var(--osdk-color-background-surface-modal);
 font-family: var(--osdk-font-family-body) !important;
  color: var(--osdk-color-content-body);
  font-weight: var(--osdk-font-weight-body);
  line-height: 1.5;
}

a{

  width: 100%;
  color: #333;
}

.onfido-sdk-ui-crossDevice-Intro-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.onfido-sdk-ui-crossDevice-Intro-stageIcon {
  background-size: contain;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  float: left;
  height: 3em;
  width: 3em;
  position: relative;
  z-index: 1;
}

  
.data-row-web {
  width: 100%;
  max-width: 1227px;

  border-radius: 8px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0px;
  padding-right: 30px;

  justify-content: space-between;
}
.data-tags {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* black */

  color: #222222;
}
.view-btn {
  width: 90px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-top: 10px;
  /* identical to box height */
  background: none;

  color: #2074f2;

  border: 1px solid #2074f2;
  border-radius: 45px;
}

.data-offer-list-row-mobile {
  display: none;
}
.row-sub-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.col-sub-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table-header-tag {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  /* black */

  color: #222222;

  opacity: 0.6;
}
@media (max-width: 1268px) {
  .data-row-web {
    display: none;
  }
  .data-offer-list-row-mobile {
    width: 100%;

    border-radius: 8px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0px;
    padding-right: 30px;

    justify-content: space-between;
  }
  .table-header-tag {
    text-align: center;
  }
  .data-tags {
    text-align: center;
  }
}
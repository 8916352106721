@import "../../styles/variables.scss";
@import "../../styles/colors.scss";

.search-container {
  max-width: $breakpoint-max;
  margin: 0 auto;
  width: 95%;
  display: flex;
  flex-direction: column;
  h1 {
    font-weight: 700;
  }
  .search-options-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0%;

    .search-option {
      display: flex;
      flex-direction: column;
      font-size: 1rem;
      .ant-select {
        cursor: pointer;
      }
      .ant-select-selector {
        &:hover,
        &:focus {
          border-color: $primaryGreen;
          outline: 0 solid $primaryGreen;
        }
      }
      .ant-input {
        line-height: 1.5;
        font-size: 1.25rem;
      }

      label {
        padding-left: 8%;
      }
    }
  }
  .advanced {
    div:nth-of-type(1) {
      flex: 1;
    }
    div:nth-of-type(2) {
      flex: 2;
    }
  }
  a {
    font-size: 1rem;
    text-align: right;
    color: $primaryGreen;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
      color: $secondaryGreen;
    }
  }
  .advanced-search {
    display: flex;
    justify-content: flex-end;
  }
}
.input_amount{
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 39px;
}
.buyabc{
  background-color: #00cc90;
  color: #fff;
}
.searchButton{
  margin-bottom: 0px !important;
}
.antborder{
  border-bottom: 1px solid lightgrey;
}

@media only screen  and (min-width : 1200px) and (max-width : 1800px) {
  .lap-option{
    height: 30px !important;
    margin-top:-30px;
  }
  }
@import "../../styles/variables.scss";
@import "../../styles/colors.scss";



.chat-text {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  width: auto;
  border-radius: 6px;
  background-color: $secondaryLightColor;
  padding: 1%;
  align-self: flex-start;
  margin: 0.5% 0;

  p {
    margin: 0;
  }
  .time {
    font-size: 0.7rem;
    margin-left: 3%;
    // align-self: flex-end;
    white-space: nowrap;
  }
}

.self {
  background-color: #06a0751a!important;
  align-self: flex-end;
  color: $primaryLightColor;
}
.lightblue{
  background-color: #06a0751a!important;
}

.lightred{
  background-color: #ffcccb70!important;
}
@import "../../styles/variables.scss";
@import "../../styles/colors.scss";

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .active-container .secondary-nav .tab-link a {
      padding: 2px !important;
      color: #4c4c4c;
  }
  .topMob{
      margin-top: 50px;
  }
  }
  

  .secondary-nav {
    display: flex;
    height: 3rem;
    margin-bottom: 3rem;
    border-bottom: 1px solid $secondaryLightColor;

    div {
      height: 50%;
      padding: 0.5rem 1rem;
      margin: auto 0;
    }
    .title {
      padding: 0 1rem;
      font-weight: bold;
      border-right: 1px solid $secondaryLightColor;
    }
    .tab-link {
      margin: auto 0 auto 2.5%;
      padding: 0;

      a {
        padding: 0.6rem;
        color: $secondaryDarkColor;

        &:hover {
          color: $primaryGreen;
        }
      }
      .is-active {
        padding: 0.6rem;
        border-bottom: 5px solid $primaryGreen;

        &:hover {
          color: $secondaryDarkColor;
          cursor: default;
        }
      }
    }
  }
  
.active-container {
  margin-bottom: 2%;
  h3 {
    font-weight: bold;
  }

  .none-active {
    p {
      color: $secondaryDarkColor;
    }

    div {
      display: flex;

      p {
        margin: auto 2%;
      }

      a {
        padding: 0.5% 1%;
        border: 1px solid $triDarkColor;
        border-radius: 4%;
        color: $primaryDarkColor;
        &:hover {
          color: $primaryGreen;
        }
      }
    }
  }
}

.privacy-body {
  padding-left: 200px;
  padding-right: 200px;
}

.pr-head {
  color: #222222 !important;
}

.list-main {
  padding: 0px;
  text-align: justify;
}

.list-txt {
  list-style: none;
}

.main-heading {
  text-align: inherit;
}

@media (max-width: 991px) {
  .privacy-body {
    padding-left: 100px;
    padding-right: 100px;
  }
  .main-heading {
    text-align: start;
  }
}

@media (max-width: 550px) {
      .privacy-body {
            padding-left: 50px;
            padding-right: 50px;
          }
          .main-heading {
            text-align: start;
          }
}

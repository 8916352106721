.withdraw-container {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-left: 55px;
}
.input-wrapper-withdraw-coin {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.input-label-withdraw-coin {
  margin: 0;
  margin-bottom: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* black */

  color: #222222;
}
.withdraw-coin-input {
  width: 100%;
  max-width: 430px;
  height: 50px;

  background: rgba(233, 233, 233, 0.4);
  border: 1px solid #e9e9e9;
  border-radius: 45px;
  cursor: not-allowed;
}

.withdraw-coin-input-amount {
  width: 100%;
  max-width: 430px;
  height: 50px;
  padding-left: 20px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 45px;
}
.submit-coin-btn {
  width: 200px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #ffffff;

  background: #2074f2;
  box-shadow: 0px 0px 14px rgba(32, 116, 242, 0.35);
  border-radius: 45px;
}
@media (max-width: 440px) {
  .withdraw-container {
    margin-left: 20px;
  }
}
